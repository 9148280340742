<template>
  <b-row class="form-group">
    <b-col md="3" class="col-form-label">{{ label }}</b-col>
    <b-col md="9" class="col-form-label">
      <div class="radio-inline">
        <label v-for="option in options" :key="option.value" class="radio mr-10" :class="`radio-${option.variant}`">
          <input
            type="radio"
            :name="`${name}-${id}`"
            :checked="option.value === value"
            :value="option.value"
            @change="onChange"
          />
          <span></span>
          {{ option.text }}
        </label>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    id: {type: Number, default: ''},
    name: {type: String, default: ''},
    options: {type: Array, default: () => []},
    variant: {type: String, default: 'primary'},
    value: {type: String, default: '0'},
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.value);
    }
  }
}
</script>