<template>
  <div>
    <b-row v-for="(categoryData, categoryName) in filteredData" :key="categoryName">
      <b-col md="3"></b-col>
      <b-col md="6">
        <div class="h4">{{ $t(`label.${categoryName}`) }}</div>

        <div v-for="item in categoryData" :key="item.id">
          <app-radio-group
            :label="item.name"
            :name="`${categoryName}-status`"
            :id="item.id"
            :options="statusOptions"
            :value="item.status"
            @change="onChange($event, item.id, categoryName, 'status')"
          />
          <app-textarea
            v-if="parseInt(form[categoryName][item.id]['status']) === 2"
            class="mt-n5"
            :label="$t('label.comment')"
            :value="item.zone_comment"
            rows="2"
            @input="onChange($event, item.id, categoryName, 'zone_comment')"
          />
          <app-radio-group
            :label="$t('label.pillow_condition')"
            :name="`${categoryName}-pillow`"
            :id="item.id"
            :options="pillowOptions"
            :value="item.pillow_condition"
            @change="onChange($event, item.id, categoryName, 'pillow_condition')"
          />
          <app-textarea
            v-if="parseInt(form[categoryName][item.id]['pillow_condition']) === 1"
            class="mt-n5"
            :label="$t('label.comment')"
            :value="item.pillow_comment"
            rows="2"
            @input="onChange($event, item.id, categoryName, 'pillow_comment')"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import i18n from '@/core/plugins/vue-i18n';
import AppRadioGroup from '@/components/form-groups/AppRadioGroup.vue';
import AppTextarea from '@/components/form-groups/AppTextarea.vue';

export default {
  components: {
    AppRadioGroup,
    AppTextarea,
  },
  props: {
    data: {type: Object, default: () => ({})}
  },
  data() {
    return {
      form: {},
      statusOptions: [
        {text: i18n.t('status.working'), value: '0', variant: 'success', checked: false},
        {text: i18n.t('status.resting'), value: '1', variant: 'primary', checked: false},
        {text: i18n.t('status.replacement'), value: '2', variant: 'danger', checked: false}
      ],
      pillowOptions: [
        {text: i18n.t('status.good'), value: '0', variant: 'success', checked: false},
        {text: i18n.t('status.bad'), value: '1', variant: 'danger', checked: false},
      ]
    }
  },
  beforeMount() {
    this.form = Vue.observable({basic: {}, reserve: {}, fire: {}});
  },
  computed: {
    filteredData() {
      return {
        basic: this.data?.basic || [],
        reserve: this.data?.reserve || [],
        fire: this.data?.fire || []
      };
    }
  },
  watch: {
    filteredData: {
      handler(data) {
        if (data) {
          Object.keys(data).forEach(categoryName => {
            data[categoryName].forEach(item => {
              const { id, name, ...params } = item;
              delete params.name;
              this.$set(this.form[categoryName], id, Vue.observable(params));
            });
          });
        }
      },
      deep: true
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  },
  methods: {
    onChange(value, id, entity, param) {
      this.$set(this.form[entity][id], param, value);
    }
  }
}
</script>