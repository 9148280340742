<template>
  <div>
    <app-card>
      <template #body>
        <water-inspection-form :block-id="blockId" :data="data" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: hash})">
        {{ $t('btn.back_to_block') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import WaterInspectionForm from './components/WaterInspectionForm.vue';

export default {
  components: {
    AppCard,
    WaterInspectionForm
  },
  data: () => ({
    blockId: null,
    form: {},
    hash: '#water_inspection'
  }),
  beforeMount() {
    this.$store.dispatch('waterInspectionStore/GET_DATA', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      data: 'waterInspectionStore/DATA'
    })
  },
  watch: {
    data(data) {
      this.blockId = data.block_id;
    }
  },
  methods: {
    updateItem() {
      const payload = {
        basic: JSON.stringify(this.form.basic),
        reserve: JSON.stringify(this.form.reserve),
        fire: JSON.stringify(this.form.fire),
      }

      this.$store.dispatch('waterInspectionStore/UPDATE', {id: this.data.id, data: payload}).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: this.hash});
      });
    }
  }
}
</script>